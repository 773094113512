// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contacto-js": () => import("./../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-en-about-js": () => import("./../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("./../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-history-js": () => import("./../../src/pages/en/history.js" /* webpackChunkName: "component---src-pages-en-history-js" */),
  "component---src-pages-en-index-js": () => import("./../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-products-broom-handles-js": () => import("./../../src/pages/en/products/broom-handles.js" /* webpackChunkName: "component---src-pages-en-products-broom-handles-js" */),
  "component---src-pages-en-products-door-frames-js": () => import("./../../src/pages/en/products/door-frames.js" /* webpackChunkName: "component---src-pages-en-products-door-frames-js" */),
  "component---src-pages-en-products-wooden-boards-js": () => import("./../../src/pages/en/products/wooden-boards.js" /* webpackChunkName: "component---src-pages-en-products-wooden-boards-js" */),
  "component---src-pages-en-sustainability-js": () => import("./../../src/pages/en/sustainability.js" /* webpackChunkName: "component---src-pages-en-sustainability-js" */),
  "component---src-pages-historia-js": () => import("./../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-productos-cabos-de-escoba-js": () => import("./../../src/pages/productos/cabos-de-escoba.js" /* webpackChunkName: "component---src-pages-productos-cabos-de-escoba-js" */),
  "component---src-pages-productos-molduras-js": () => import("./../../src/pages/productos/molduras.js" /* webpackChunkName: "component---src-pages-productos-molduras-js" */),
  "component---src-pages-productos-tableros-js": () => import("./../../src/pages/productos/tableros.js" /* webpackChunkName: "component---src-pages-productos-tableros-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-sustentabilidad-js": () => import("./../../src/pages/sustentabilidad.js" /* webpackChunkName: "component---src-pages-sustentabilidad-js" */)
}

